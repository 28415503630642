$(document).ready(function(){
    // Cache the Window object
    $window = $(window);
  
    $('[data-parallax]').each(function(){
      var $image = $(this); // assigning the object
    
        $(window).scroll(function() {
    
          if ($image.data('parallax') == 'down')  {
            var yPos = ($window.scrollTop() / $image.data('speed'));
          } else {
            var yPos = -($window.scrollTop() / $image.data('speed'));
          }
    
          // Scroll the background at var speed
          // the yPos is a negative value because we're scrolling it UP!								
          //var yPos = -($window.scrollTop() / $image.data('speed'));
    
          // Put together our final background position
          var coords = '50% '+ yPos + 'px';
          
          // Move the background
          $image.css( "background-position", coords );
    
        }); // window scroll Ends
    
      });
  
  }); 